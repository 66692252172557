<template>
  <el-dialog :visible.sync="visible" title="导入" :close-on-click-modal="false" :close-on-press-escape="false">
    <el-radio-group v-model="importType" @input="changeImportType">
      <el-radio label="1">按国标编码导入</el-radio>
      <el-radio label="2">按全码导入</el-radio>
    </el-radio-group>
    <el-row>
      <el-col :span="24">
        <img :src="thumbnail" style="width: 100%;">
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-upload
          name="file"
          :action="actionUrl"
          :file-list="fileList"
          drag
          :before-upload="beforeUploadHandle"
          :on-success="successHandle"
          class="text-center my-excel-import-dialog">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text" v-html="$t('upload.text')"></div>
        </el-upload>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
// 项目节范本导入，兼容【全码】、【国标编码】两种导入方式
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      actionUrl: '',
      visible: false,
      mainId: '',
      fileList: [],
      importLoading: {},
      // 1：按国标编码，2：按全码
      importType: '1',
      thumbnail: require(`@/assets/img/import/import_estgb_code.jpg`)
    }
  },
  methods: {
    init (mainId) {
      this.mainId = mainId
      this.visible = true
      this.fileList = []
      this.actionUrl = `${window.SITE_CONFIG['apiURL']}/mps/estGbMain/import?access_token=${Cookies.get('access_token')}&tenant_code_key=${Cookies.get('tenantCode')}&importType=${this.importType}&mainId=${this.mainId}`
    },
    changeImportType() {
      if (this.importType == 1) {
        this.thumbnail = require(`@/assets/img/import/import_estgb_code.jpg`)
      } else if (this.importType == 2) {
        this.thumbnail = require(`@/assets/img/import/import_estgb_keycode.jpg`)
      }
      // 重新设置importType参数
      this.actionUrl = `${window.SITE_CONFIG['apiURL']}/mps/estGbMain/import?access_token=${Cookies.get('access_token')}&tenant_code_key=${Cookies.get('tenantCode')}&importType=${this.importType}&mainId=${this.mainId}`
    },
    beforeUploadHandle (file) {
      this.importLoading = this.$loading({
        text: '正在导入，请稍等...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    successHandle (res, file, fileList) {
      this.importLoading.close()
      this.visible = false
      if (res.code !== 0) {
        return this.$message({
          message: res.msg,
          type: 'error',
          duration: 30000
        })
      }
      this.$emit('refreshDataList')
      this.$message({
        message: this.$t('prompt.success'),
        type: 'success',
        duration: 5000,
        onClose: () => {
          // do nothing
        }
      })
    }
  }
}
</script>
<style scoped>
.my-excel-import-dialog {
  margin-top: 60px;
  margin-bottom: 90px;
}
</style>