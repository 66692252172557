<template>
  <el-dialog v-dialog-drag width="80%" :visible.sync="visible" :title="dialogTitle" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <vxe-table
        :data="dataList"
        :row-config="{isCurrent: true, isHover: true}"
        :max-height="tableHeight"
        :edit-config="{trigger: 'click', mode: 'cell', showStatus: true}"
        show-overflow>
      <vxe-column field="code" title="项次" width="90" align="center" fixed="left"></vxe-column>
      <vxe-column field="name" title="检查项目" min-width="200" align="left" fixed="left">
        <template v-slot="{ row }">
          {{ (row.parentName != null ? (row.parentName + ' / ') : '') + row.name }}
        </template>
      </vxe-column>
      <vxe-column field="formula" title="规定值或允许偏差" min-width="200" :edit-render="{name: '$input'}">
        <template #header>
          <span>规定值或允许偏差</span>
          <el-popover ref="formulaPopover" placement="right" trigger="hover">
            <!-- 可在此处 补充其他内容-->
            <i slot="reference" class="el-icon-info" style="margin-left:5px;color: #F56C6C"></i>
          </el-popover>
        </template>
      </vxe-column>
      <vxe-column field="basis" title="判定依据" width="120" :edit-render="{name: '$select', props: {options: basisList}}">
        <template #default="{ row }">
          <span>{{ formatBasis(row.basis) }}</span>
        </template>
      </vxe-column>
      <vxe-column field="appendix" title="评定标准附录" width="180" :edit-render="{name: '$select', props: {options: appendixList}}">
        <template #default="{ row }">
          <span>{{ formatAppendix(row.appendix) }}</span>
        </template>
      </vxe-column>
      <vxe-column field="isKeyItem" title="是否关键项目" align="center">
        <template v-slot="{ row }">
          <vxe-radio-group v-model="row.isKeyItem">
            <vxe-radio :label="0" content="一般项目"></vxe-radio>
            <vxe-radio :label="1" content="关键项目"></vxe-radio>
          </vxe-radio-group>
        </template>
      </vxe-column>
    </vxe-table>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataList: [],
      dataForm: {
        id: '',
      },
      tableHeight: 300,
      dialogTitle: '质量检验检查项',
      basisList: [  // 判定依据
        {label: '实测值', value: 1},
        {label: '偏差值', value: 2},
        {label: '满足设计要求', value: 3}
      ],
      appendixList: [  // 附录
        {label: '无', value: 'NONE'},
        {label: '附录B(基层、底基层)', value: 'B1'},
        {label: '附录B(路基、路面层)', value: 'B2'},
        {label: '附录B(沥青面层)', value: 'B3'},
        {label: '附录C', value: 'C'},
        {label: '附录D', value: 'D'},
        {label: '附录E', value: 'E'},
        {label: '附录F', value: 'F'},
        {label: '附录G', value: 'G'},
        {label: '附录H(基层、底基层)', value: 'H1'},
        {label: '附录H(面层)', value: 'H2'},
        {label: '附录L', value: 'L'},
        {label: '附录M', value: 'M'},
        {label: '附录N', value: 'N'}
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight * 0.8 - 100
    })
  },
  methods: {
    init(fileName) {
      this.visible = true
      this.$nextTick(() => {
        this.dialogTitle = `${fileName}-检查项`
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 判定依据
    formatBasis(value) {
      if (value === 1) {
        return '实测值'
      } else if (value === 2) {
        return '偏差值'
      } else if (value === 3) {
        return '满足设计要求'
      }
      return ''
    },
    // 附录
    formatAppendix(value) {
      if (value == 'B1') {
        return '附录B(基层、底基层)'
      } else if (value == 'B2') {
        return '附录B(路基、路面层)'
      } else if (value == 'B3') {
        return '附录B(沥青面层)'
      } else if (value == 'C') {
        return '附录C'
      } else if (value == 'D') {
        return '附录D'
      } else if (value == 'E') {
        return '附录E'
      } else if (value == 'F') {
        return '附录F'
      } else if (value == 'G') {
        return '附录G'
      } else if (value == 'H1') {
        return '附录H(基层、底基层)'
      } else if (value == 'H2') {
        return '附录H(面层)'
      } else if (value == 'L') {
        return '附录L'
      } else if (value == 'M') {
        return '附录M'
      } else if (value == 'N') {
        return '附录N'
      }
      return ''
    },
    
    // 获取信息
    getInfo() {
      this.$http.get('/mps/qcFile/itemList/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$http.post(`/mps/qcFile/updateItem`, this.dataList).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.visible = false
          }
        })
      }).catch(() => {
      })
    }, 1000, {'leading': true, 'trailing': false}),
  }
}
</script>