<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__preproblem">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <pre-project-select v-model="dataForm.prePrjId" placeholder="请选择项目" :default-select="true" @change="preProjectSelectHandle"></pre-project-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.title" placeholder="标题" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:preproblem:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:preproblem:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="title" label="标题" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{ row.title }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="问题状态" width="100" align="center" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.state==1" type="danger" effect="plain">待解决</el-tag>
            <el-tag v-if="scope.row.state==2" type="warning" effect="plain">进行中</el-tag>
            <el-tag v-if="scope.row.state==3" type="success" effect="plain">已解决</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="principal" label="负责人" width="120" sortable="custom" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resolvedDate" label="解决日期" width="120" sortable="custom" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="description" label="问题描述" width="250" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="140">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('opm:preproblem:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:preproblem:delete')" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:preproblem:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './preproblem-add-or-update'
import ViewPage from './preproblem-view'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/opm/preProblem/page',
        getDataListIsPage: true,
        deleteURL: '/opm/preProblem',
      },
      dataForm: {
        title: '',
        prePrjId: ''
      }
    }
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 前期项目选择
    preProjectSelectHandle() {
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      // 组件传入了不同的参数，重写混入的方法
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.prePrjId = this.dataForm.prePrjId
        this.$refs.addOrUpdate.init()
      })
    },
  }
}
</script>