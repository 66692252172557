<template>
  <vxe-pulldown ref="xDown" transfer style="width: 100%">
    <template #default>
      <el-tooltip placement="top-start" :content="name" effect="light" :enterable="false" :open-delay="500">
        <vxe-input v-model="name" style="width: 100%;" size="medium" :suffix-icon="!readonly?'vxe-icon-search':''" :placeholder="placeholder" @click="showDropdown" readonly></vxe-input>
      </el-tooltip>
    </template>
    <template #dropdown>
      <vxe-table
        max-height="300"
        show-overflow
        :row-config="{isHover: true,isCurrent: true,useKey: true}"
        :column-config="{resizable: true}"
        :tree-config="treeConfig"
        :data="tableData"
        size="mini"
        @cell-click="cellClickEvent"
        :scroll-y="{enabled: false}">
        <vxe-column field="name" title="名称" tree-node></vxe-column>
      </vxe-table>
    </template>
  </vxe-pulldown>
</template>
<!--质检用表目录选择-->
<script>
  export default {
    name: 'QcfileDirSelect',
    data () {
      return {
        treeConfig: {
          transform: true,
          rowField: 'id',
          parentField: 'pid',
          line: true,
          lazy: true,
          hasChild: 'hasChildren',
          loadMethod: this.loadChildren,
          iconOpen: 'vxe-icon-square-minus',
          iconClose: 'vxe-icon-square-plus'
        },
        tableData: [],
        name: ''
      }
    },
    created: function () {
      this.getRootData()
    },
    props: {
      value: String,
      placeholder: String,
      readonly: Boolean,
      // 默认只显示节点名称,传入true则显示完整路径，
      showPNames: {
        type: Boolean,
        default: false
      },
      isLeafSelect:{
        type: Boolean,
        default: false
      },
      prjId: String // 项目ID
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    watch: {
      // 数据回显
      value: function (newVal,oldVal) {
        this.showName(newVal)
      }
    },
    methods: {
      showDropdown () {
        if (!this.readonly) {
          this.$refs.xDown.showPanel()
        }
      },
      cellClickEvent ({row,triggerTreeNode}) {
        // 如果是折叠/展开树的操作，就不触发点击事件
        if (!triggerTreeNode) {
          // 只能叶子节点选中
          if (this.isLeafSelect) {
            if(!row.isLeaf) {
              return this.$message.warning("请选择最末级节点")
            }
          }
          if (this.showPNames) {
            this.name = row.pnames ? (row.pnames + '，' + row.name) : row.name
          } else {
            this.name = row.name
          }
          this.$emit('change',row.id)
          this.$refs.xDown.hidePanel()
        }
      },

      showName (id) {
        if (id) {
          this.$http.get('/mps/qcFileDir/' + id).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            if (this.showPNames) {
              this.name = res.data.pnames ? (res.data.pnames + '，' + res.data.name) : res.data.name
            } else {
              this.name = res.data.name
            }
          }).catch(() => {
            // do nothing
          })
        } else {
          this.name = ''
        }
      },
      loadChildren ({row}) {
        // 异步加载子节点
        return new Promise(resolve => {
          this.$http.get(
            '/mps/qcFileDir/children',
            {
              params: {
                pid: row.id,
                prjId: this.prjId
              }
            }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            resolve(res.data)
          }).catch(() => {
            // do nothing
          })
        })
      },
      getRootData () {
        this.$http.get(
          '/mps/qcFileDir/children',
          {
            params: {
              pid: 0,
              prjId: this.prjId
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.tableData = res.data
        }).catch(() => {
          // do nothing
        })
        this.showName(this.value)
      },
    },
  }
</script>
<style scoped>
</style>
