<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__estGb">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('mps:estGb:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:estGb:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="name" label="范本名称" min-width="150" align="left" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="sortNo" label="排序" width="80" header-align="center" align="center" sortable="custom" show-overflow-tooltip></el-table-column> -->
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="200">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('mps:estGb:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="import" v-if="$hasPermission('mps:estGb:import')" @click="estGbImportHandle(row.id)"></table-button>
            <table-button role="export" v-if="$hasPermission('mps:estGb:export')" @click="estGbExportHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('mps:estGb:delete')" @click="deleteHandle(row.id)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <!-- 自定义的excel导入弹窗 -->
      <estgb-excel-import v-if="estGbExcelImportVisible" ref="estGbExcelImport" @refreshDataList="getDataList"></estgb-excel-import>
      <!-- 支持按全码导出、按国标编码导出 -->
      <el-dialog :visible.sync="exportVisible" title="导出" :close-on-click-modal="false" :close-on-press-escape="false" width="300px">
        <div style="text-align: center; padding-top: 10px; padding-bottom: 50px;">
          <el-button-group>
            <el-button round @click="doExport(1)">按国标编码导出</el-button>
            <el-button round @click="doExport(2)">按全码导出</el-button>
          </el-button-group>
        </div>
      </el-dialog>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './estgbmain-add-or-update'
import EstgbExcelImport from './estgb-excel-import'
import ViewPage from './estgbmain-view'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/estGbMain/page',
        getDataListIsPage: true,
        exportURL: '/mps/estGbMain/export',
        deleteURL: '/mps/estGbMain',
      },
      dataForm: {
        name: '',
        mainId: null,
        exportType: 1,
      },
      order: 'desc', // 排序，asc／desc
      orderField: 'sort_no',
      estGbExcelImportVisible: false,
      exportVisible: false
    }
  },
  components: {
    AddOrUpdate,
    ViewPage,
    EstgbExcelImport
  },
  methods: {
    // 导出
    estGbExportHandle (id) {
      this.dataForm.mainId = id
      this.exportVisible = true
    },
    doExport(type) {
      this.dataForm.exportType = type
      this.exportHandle()
      this.exportVisible = false
    },
    // 导入
    estGbImportHandle (id) {
      this.dataForm.mainId = id
      this.estGbExcelImportVisible = true
      this.$nextTick(() => {
        this.$refs.estGbExcelImport.init(this.dataForm.mainId)
      })
    },
    viewHandle (id) {
      this.viewVisible = true
      this.$nextTick(() => {
        this.$refs.view.mainId = id
        this.$refs.view.init()
      })
    },
  }
}
</script>