<template>
  <el-dialog :visible.sync="visible" :title="title" v-on="$listeners" append-to-body>
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="单位" prop="unitName">
            <el-input v-model="dataForm.unitName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同工程量">
            <el-input :value="numFormatter(contractNum, dataForm.accuracy)" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上期末完成">
            <el-input :value="numFormatter(sumByLastPeriod, dataForm.accuracy)" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="本期申请" prop="num">
            <el-input :value="numFormatter(dataForm.num, dataForm.accuracy)" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="审定数量" prop="checkedNum">
            <el-input :value="numFormatter(dataForm.checkedNum, dataForm.accuracy)" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import XEUtils from "xe-utils";

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        measId: '',
        boqId: '',
        code: '',
        ctoa: '',
        num: '',
        checkedNum: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
      },
      title: '计量明细',
      contractNum: '',
      sumByLastPeriod: '',
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/measMx/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
    numFormatter(value, accuracy) {
      return XEUtils.isNumber(accuracy) ? XEUtils.commafy(value,{digits: accuracy}) : value
    }
  }
}
</script>
