<template>
  <el-dialog :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="auto">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="code" label="工程或费用编码">
            <el-input v-model="dataForm.code" placeholder="工程或费用编码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="name" label="工程或费用名称">
            <el-input v-model="dataForm.name" placeholder="工程或费用名称"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="unitId" label="计量单位">
            <unit-select v-model="dataForm.unitId" :prj-id="dataForm.prjId"></unit-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="上级节点" prop="parentName">
            <sbs-select v-model="dataForm.pid" :contractId="dataForm.contractId" :subcontractor-id="dataForm.subcontractorId" :disabledId="dataForm.id" :leaf-only="false"></sbs-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="全码" prop="keyCode">
            <span style="color:coral;">{{ dataForm.keyCode }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="level" label="概算类型">
            <span v-if="dataForm.level == 1" style="color:rgb(34, 11, 240);">部</span>
            <span v-if="dataForm.level == 2" style="color:rgb(238, 197, 17);">项</span>
            <span v-if="dataForm.level == 3" style="color:blueviolet;">目</span>
            <span v-if="dataForm.level == 4" style="color:chocolate;">节</span>
            <span v-if="dataForm.level >= 5" style="color:green;">细目</span>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="关联分部分项" prop="wbsId">
            <wbs-select v-model="dataForm.wbsId" :disabled="wbsCanSelect" :contractId="dataForm.contractId" :subcontractorId="dataForm.subcontractorId" placeholder="选择分部分项" showPNames :leaf-only="true"></wbs-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工程实体" prop="isEntity">
            <el-switch v-model="dataForm.isEntity" :active-value="true" :inactive-value="false"></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="sortNo" label="排序序号">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序序号"></el-input-number>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="16">
          <el-form-item v-if="dataForm.pnames" label="名称路径" prop="pnames">
            {{ dataForm.pnames }}
          </el-form-item>
        </el-col> -->
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      defaultParentName: '无',
      dataForm: {
        id: '',
        prjId: '',
        contractId: '',
        subcontractorId: '',
        pid: '',
        name: '',
        code: '',
        keyCode: '',
        level: '',
        unitId: '',
        sortNo: 0,
        num1: '',
        num2: '',
        money: '',
        parentName: '无',
        isEntity: '',
      },
      oldPid: '',
      wbsCanSelect: true
    }
  },
  computed: {
    dataRule() {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
          // 校验该标段分项是否已参与计量，如果已参与计量则不允许修改分部分项
          this.$http.get(`/mps/meas/isSubItemMeasured/${this.dataForm.id}`).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.wbsCanSelect = res.data
          }).catch(() => {
            return this.$message.error('出错了')
          })
        } else {
          // 关联分部分项可选择
          this.wsbCanSelect = false
          // 获取最新全码
          this.getNewKeyCode()
          // 获取排序序号
          this.getNewSortNo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get(`/mps/sbs/${this.dataForm.id}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        if (this.dataForm.pid === '0') {
          this.dataForm.parentName = this.defaultParentName
        }
        this.oldPid = this.dataForm.pid
      }).catch(() => {
        // do nothing
      })
    },
    // 获取【全码】
    getNewKeyCode() {
      this.$http.get('/mps/sbs/newKeyCode', {
        params: {
          prjId: this.dataForm.prjId,
          contractId: this.dataForm.contractId,
          subcontractorId: this.dataForm.subcontractorId,
          pid: this.dataForm.pid
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (!res.data) {
          return this.$message.error("全码自动生成失败！")
        }
        this.dataForm.keyCode = res.data
      }).catch(() => {
        // do nothing
      })
    },
    // 获取排序序号
    getNewSortNo() {
      this.$http.get('/mps/sbs/newSortNo', {
        params: {
          prjId: this.dataForm.prjId,
          contractId: this.dataForm.contractId,
          subcontractorId: this.dataForm.subcontractorId,
          pid: this.dataForm.pid
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm.sortNo = res.data
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/sbs', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          if (this.dataForm.id) {
            this.$emit('updateNode', this.dataForm.pid, this.oldPid != this.dataForm.pid)
          } else {
            this.$emit('addNode', this.dataForm.pid)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
            }
          })
        }).catch(() => {
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>
