<template>
  <el-dialog v-dialog-drag width="60%" :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="质检目录" prop="dirId">
            <qcfile-dir-select v-model="dataForm.dirId" :prjId="dataForm.prjId" placeholder="请选择质检用表目录" showPNames readonly></qcfile-dir-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="表号" prop="code">
            <el-input v-model="dataForm.code" placeholder="表号" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="文件名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="文件名称" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="施工步骤" prop="typeId">
            <el-select v-model="dataForm.typeId" placeholder="请选择" class="w-percent-100" disabled>
              <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="dsId" label="数据源">
            <el-select v-model="dataForm.dsId" class="w-percent-100" disabled>
              <el-option :value="0" label="通用数据源"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input v-model="dataForm.sortNo" placeholder="排序序号" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" :autosize="{ minRows: 2,maxRows:10 }" placeholder="备注" v-model="dataForm.remark" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <my-upload-plain :refId="dataForm.id" typeId="质检用表" :statusId=3 view></my-upload-plain>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import QcfileDirSelect from "./qcfile-dir-select.vue";

export default {
  components: {QcfileDirSelect},
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        dirId: '',
        code: '',
        name: '',
        typeId: '',
        remark: '',
        sortNo: '',
        tenantCode: ''
      },
      typeOptions: [{
        value: 1,
        label: '开工报告'
      }, {
        value: 2,
        label: '检验申请批复'
      }, {
        value: 3,
        label: '交工证书'
      }]
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/qcFile/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
  }
}
</script>
