<template>
  <div class="mod-__qcfile">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-input v-model="dataForm.keyword" placeholder="表号或文件名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="dataForm.typeId" clearable placeholder="施工步骤" @clear="typeClear" @change="typeChange">
          <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group v-if="dataForm.prjId" style="margin-top: -3px;">
          <toolbar-button role="query" @click="getDataList()"></toolbar-button>
          <toolbar-button role="add" v-if="$hasPermission('mps:qcfile:save')" @click="addOrUpdateHandle()"></toolbar-button>
          <toolbar-button role="delete" v-if="$hasPermission('mps:qcfile:delete')" @click="deleteHandle()"></toolbar-button>
          <toolbar-button role="import" v-if="$hasPermission('mps:qcfile:import')" @click="importHandle" :disabled="!showImportButton || dataList.length != 0"></toolbar-button>
          <toolbar-button v-if="$hasPermission('mps:qcfile:import')" name="检查库导入" icon="el-icon-top-left" @click="qcFileItemImportHandle"></toolbar-button>
          <toolbar-button v-if="$hasPermission('mps:qcfile:export')" name="检查库导出" icon="el-icon-top-right" @click="exportHandle"></toolbar-button>
          <toolbar-button icon="el-icon-delete" name="清空" v-if="$hasPermission('mps:qcfile:empty')" @click="clear()"></toolbar-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table v-loading="dataListLoading" :max-height="tableHeight" :data="dataList" highlight-current-row border 
      @sort-change="dataListSortChangeHandle" 
      @selection-change="dataListSelectionChangeHandle" 
      style="width: 100%;" :cell-style="{padding: '2px 0'}">
      <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
      <el-table-column prop="name" label="文件名称" sortable="custom" min-width="300" show-overflow-tooltip>
        <template v-slot="{row}">
          <el-button type="text" size="small" @click="viewHandle(row.id)">{{ row.name }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="pnames" label="文件目录" show-overflow-tooltip width="280" sortable="custom">
        <template v-slot="{row}">
          {{ row.pnames ? row.pnames + '/' + row.dirName : row.dirName }}
        </template>
      </el-table-column>
      <el-table-column prop="code" label="表号" sortable="custom" width="140" show-overflow-tooltip></el-table-column>
      <el-table-column prop="typeId" label="施工步骤" width="120" align="center" sortable="custom" show-overflow-tooltip>
        <template v-slot="{row}">
          <span v-if="row.typeId === 1" style="color: #5231e6">开工报告</span>
          <span v-else-if="row.typeId === 2" style="color: #2278b2">检验申请批复</span>
          <span v-else-if="row.typeId === 3" style="color: #8ea706">交工证书</span>
          <span v-else style="color: #ee0a30">未知</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="210">
        <template v-slot="{row}">
          <table-button :disabled="!row.fileId" name="在线编辑" icon="my-icon-sharpicons_crop" @click="openDocumentEditor(row.fileId, 'edit')"></table-button>
          <table-button role="update" v-if="$hasPermission('mps:qcfile:update')" @click="addOrUpdateHandle(row.id)"></table-button>
          <table-button :disabled="row.name.indexOf('检验评定表') < 0" name="检查项目库" icon="my-icon-edit" v-if="$hasPermission('mps:qcfile:update')" @click="editItemHandle(row.id, row.name)"></table-button>
          <table-button role="delete" v-if="$hasPermission('mps:qcfile:delete')" @click="deleteHandle(row.id)"></table-button>
          <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:qcfile:update'))"></table-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
    </el-pagination>
    <!-- 弹窗, 查看 -->
    <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    <!-- 弹窗, 新增 / 修改 -->
    <edit-item v-if="editItemVisible" ref="editItem" @close="closeCustomDialogHandle"></edit-item>
    <!-- excel文件导入弹窗 -->
    <excel-import v-if="excelImportVisible" ref="excelImport" @refreshDataList="refreshDataHandle" url="/mps/qcFile/import" thumbnail="import_qczipfile.jpg"></excel-import>
    <!-- 检查项目库导入弹窗 -->
    <excel-import v-if="qcfileItemImportVisible" ref="qcFileItemImport" @refreshDataList="refreshDataHandle" url="/mps/qcFile/item/import" thumbnail="import_qcfileitem.jpg"></excel-import>
  </div>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './qcfile-add-or-update'
import ViewPage from './qcfile-view'
import EditItem from './qcfile-item-edit'
import qs from "qs";

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/mps/qcFile/page',
        getDataListIsPage: true,
        deleteURL: '/mps/qcFile',
        exportURL: '/mps/qcFile/item/export' // 质检检查项目库导出
      },
      dataForm: {
        prjId: '', // 项目ID
        dirId: '', // 质检目录ID
        keyword: '', // 按表号或表格名称检索
        typeId: '' // 施工步骤
      },
      typeOptions: [{
        value: '1',
        label: '开工报告'
      }, {
        value: '2',
        label: '检验申请批复'
      }, {
        value: '3',
        label: '交工证书'
      }],
      editItemVisible: false,
      qcfileItemImportVisible: false
    }
  },
  props: {
    tableHeight: {
      type: Number,
      required: true,
      default: 400
    },
    showImportButton: Boolean,
    prjId: String
  },
  components: {
    AddOrUpdate,
    ViewPage,
    EditItem
  },
  watch: {
    // 监听项目ID的变化
    prjId() {
      this.dataForm.prjId = this.prjId
      this.dataList = []  // 清空列表数据
    }
  },
  methods: {
    initData() {
      this.getDataList()
    },
    // 导入完成后,刷新质检列表，同时刷新目录
    refreshDataHandle() {
      this.initData()
      this.$emit("refreshData")
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        if (!id) {
          this.$refs.addOrUpdate.dataForm.dirId = this.dataForm.dirId
        }
        this.$refs.addOrUpdate.init()
      })
    },
    // 查看详情
    viewHandle(id) {
      this.viewVisible = true
      this.$nextTick(() => {
        this.$refs.view.dataForm.id = id
        this.$refs.view.dataForm.prjId = this.dataForm.prjId
        this.$refs.view.init()
      })
    },
    // 清空质检模板库和目录
    clear() {
      this.$confirm(`确定要清空全部的质检用表和相关数据吗?`, this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '正在清空...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$http.post(`/mps/qcFile/${this.dataForm.prjId}/clear`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: '已成功提交清空命令!',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.query()
              this.$emit("refreshData")
            }
          })
        }).finally(() => {
          loading.close()
        })
      }).catch(() => {
        // do nothing
      })
    },

    typeClear() {
      this.dataForm.typeId = ''
      this.getDataList()
    },

    typeChange() {
      this.getDataList()
    },
    editItemHandle(id, name) {
      this.editItemVisible = true
      this.$nextTick(() => {
        this.$refs.editItem.dataForm.id = id
        this.$refs.editItem.init(name)
      })
    },
    closeCustomDialogHandle() {
      this.editItemVisible = false
    },

    // 导入质检检查项目库
    qcFileItemImportHandle(){
      this.qcfileItemImportVisible = true
      this.$nextTick(() => {
        this.$refs.qcFileItemImport.init(() => {
          // 解决导入组件实时获取到页面中动态变化的参数
          return qs.stringify(this.dataForm)
        })
      })
    },
  }
}
</script>