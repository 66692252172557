<template>
  <el-dialog v-dialog-drag width="60%" :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="质检目录" prop="dirId">
            <qcfile-dir-select v-model="dataForm.dirId" :prjId="dataForm.prjId" placeholder="请选择质检用表目录" showPNames></qcfile-dir-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="表号" prop="code">
            <el-input v-model="dataForm.code" placeholder="表号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="文件名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="文件名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="施工步骤" prop="typeId">
            <el-select v-model="dataForm.typeId" placeholder="请选择" class="w-percent-100">
              <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="dsId" label="数据源">
            <el-select v-model="dataForm.dsId" clearable placeholder="请绑定数据源" class="w-percent-100">
              <el-option :value="0" label="通用数据源"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序序号" class="w-percent-100"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" :autosize="{ minRows: 2,maxRows:10 }" placeholder="备注" v-model="dataForm.remark"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-upload accept=".xls,.xlsx" action="" :file-list="fileList" :show-file-list="true"
                       name="file" :auto-upload="false" :on-remove="handleRemove" :on-change="handleChange" :limit="1">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
              <div slot="tip">支持Excel</div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="dataForm.id && this.fileList.length==0">
          <el-form-item>
            <my-upload-plain :refId="dataForm.id" typeId="质检用表" :statusId=3 view></my-upload-plain>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import QcfileDirSelect from './qcfile-dir-select'

export default {
  components: {
    QcfileDirSelect
  },
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        dirId: '',
        code: '',
        name: '',
        typeId: '',
        remark: '',
        sortNo: '',
        tenantCode: ''
      },
      typeOptions: [{
        value: 1,
        label: '开工报告'
      }, {
        value: 2,
        label: '检验申请批复'
      }, {
        value: 3,
        label: '交工证书'
      }],
      fileList: [],
      uploadList: [],
    }
  },
  computed: {
    dataRule() {
      return {
        code: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        dirId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/qcFile/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        let formData = new FormData()
        if (this.fileList && this.fileList.length > 0) {
          formData.append('file', this.fileList[0].raw)
        }
        formData.append('dto', JSON.stringify(this.dataForm))
        this.$http[!this.dataForm.id ? 'post' : 'put'](`/mps/qcFile?_t=${new Date()}`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
        })
      })
    }, 1000, {'leading': true, 'trailing': false}),

    // 删除上传文件
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    // 文件状态改变钩子
    handleChange(file, fileList) {
      if (file && !this.dataForm.name) {
        // 文件名称字段为空时默认取上传的文件名称
        var dotIndex = file.name.lastIndexOf('.');
        if (dotIndex !== -1) {
          this.dataForm.name = file.name.substring(0, dotIndex)
        }
      }
      this.fileList = fileList
    }
  }
}
</script>