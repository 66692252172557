<template>
  <el-dialog v-dialog-drag :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="标题" prop="title">
            <el-input v-model="dataForm.title" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="问题状态" prop="state">
            <el-select v-model="dataForm.state" disabled class="w-percent-100">
              <el-option label="待解决" :value=1></el-option>
              <el-option label="进行中" :value=2></el-option>
              <el-option label="已解决" :value=3></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人" prop="principal">
            <el-input v-model="dataForm.principal" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="解决日期" prop="resolvedDate">
            <el-date-picker type="date" readonly value-format="yyyy-MM-dd" v-model="dataForm.resolvedDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="关联任务" prop="workLibId">
            <pre-worklib-select v-model="dataForm.workLibId" readonly></pre-worklib-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input v-model="dataForm.sortNo" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="问题描述" prop="description">
            <el-input type="textarea" v-model="dataForm.description" readonly :autosize="{ minRows: 3, maxRows: 10}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注说明" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" readonly :autosize="{ minRows: 3, maxRows: 10}"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        prePrjId: '',
        title: '',
        state: '',
        principal: '',
        resolvedDate: '',
        workLibId: '',
        description: '',
        sortNo: '',
        remark: '',
        tenantCode: '',
        creator: '',
        createDate: '',
        updater: '',
        updateDate: ''
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/preProblem/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
  }
}
</script>
